import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { cancelOrder, getOrderDetails } from "../Services/Order";
import '../Css/Order.css';
import { capitalizeFirstLetter } from "../Services/StringConversion";
import { formatISTDateTime } from "../Services/DateAndTime";
import { clearBrowser, isTokenValid } from "../Services/Login";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "./LoadingOverlay";
import Cookies from 'js-cookie';
import Menu from "./BottomMenu";
import { formatOrderDateTime } from "../Services/Helper";
import { CartContext } from "./CartContext";
import Loader from "./Loader";
import StarRating from "./StarRating";
import { Modal } from "react-bootstrap";
import OrderTracker from "./OrderTracker";

const OrderPage = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [processing, setProcessing] = useState(false);
    const { isLoading, setIsLoading } = useContext(CartContext);

    const statusMap = {
        "received": 0,
        "confirmed": 1,
        "assigned": 2,
        "completed": 3,
        "canceled": 4
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setProcessing(true);
        setIsLoading(true);
        if (Cookies.get('login') !== 'true') {
            setIsLoading(false);
            navigate('/login');
        } else {

            isTokenValid(Cookies.get('token'))
                .then((response) => {
                    getOrderDetails(Cookies.get('userId')).then(response => {
                        setOrders(response.data);
                        setProcessing(false)
                        setIsLoading(false);
                    }).catch(error => {

                        setProcessing(false);
                        setIsLoading(false);
                        console.log(error);
                    });

                    // else {
                    //     setProcessing(false)
                    //     clearBrowser();
                    //     navigate('/login');
                    // }
                }).catch((error) => {
                    setProcessing(false);
                    setIsLoading(false);
                    if (error.response.status === 401) {
                        alert("You are not authorized to use this app, please login");
                        navigate('/login');
                    }
                })
        }
        // Fetch order details when component mounts

    }, [pathname]);

    const handleCancel = (orderId) => {
        setIsLoading(true);
        cancelOrder(orderId).then((response) => {
            getOrderDetails(Cookies.get('userId')).then(response => {
                setOrders(response.data);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                console.log(error);
            });
        }).catch((error) => {
            setIsLoading(false);
            setProcessing(false);
            console.log(error);
        });
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            {processing && <LoadingOverlay />}
            <div className="order-page-wrapper">
                <Container className="order-page-container mt-4 scrollable-order-items">
                    <div style={{ backgroundColor: '', textAlign: 'center' }} className="justify-content-center">
                        <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Booking History</p>
                    </div>
                    {orders && orders.length > 0 ? (
                        orders.map((order, index) => (
                            <Row key={index} className="justify-content-center mb-4 ">
                                <Col md={8} className="column  ">
                                    <Card className="order-card">
                                        <Card.Body>
                                            <Card.Text>
                                                <div className="order-header" >
                                                    {/* <p>Order Id - {order._id}</p> */}
                                                    {/* <p>{formatISTDateTime(order.date, order.time)}</p> */}
                                                </div>
                                                <div className="order-header" >
                                                    <p>
                                                        Address: {capitalizeFirstLetter(order.address.address)},{" "}
                                                        {capitalizeFirstLetter(order.address.cityId.name)},{" "}
                                                        {capitalizeFirstLetter(order.address.cityId.state)}
                                                    </p>

                                                    <p
                                                        style={
                                                            order.orderStatus === "order not placed" || order.orderStatus === "pending" || order.orderStatus === "canceled"
                                                                ? { color: "red", fontSize: "14px" }
                                                                : { color: "green", fontSize: "14px" }
                                                        }
                                                    >
                                                        {capitalizeFirstLetter(order.orderStatus)}
                                                    </p>
                                                </div>
                                                <hr />
                                                {order.cartItem.map((item, itemIndex) => (
                                                    <div key={itemIndex} className="order-body">
                                                        <div className="order-body-name">
                                                            <p >
                                                                {capitalizeFirstLetter(item.itemName) + "      " + "(" + item.quantity + ")"}
                                                                <div style={{ fontSize: '13px', color: 'grey' }}>({capitalizeFirstLetter(item.productName)})</div>
                                                            </p>
                                                        </div>
                                                        <div style={{ textAlign: 'start' }}><p>{item.quantity + " X " + item.totalPrice.$numberDecimal}</p></div>
                                                        <div><p>₹{item.totalPrice.$numberDecimal * item.quantity}</p></div>
                                                    </div>
                                                ))}
                                            </Card.Text>
                                            <div className="order-header">
                                                <p style={{ fontSize: "13px" }}>Platform Fee</p>
                                                {order.platformFee.$numberDecimal > 0 ? (
                                                    <>

                                                        <p>₹{order.platformFee.$numberDecimal}</p>
                                                    </>

                                                ) : (
                                                    <p style={{ color: "green", fontSize: "13px" }}>0</p>

                                                )}

                                            </div>
                                            {order.professional && (<p style={{ marginTop: '10px', fontSize: '13px' }} >
                                                Professional Name: {capitalizeFirstLetter(order.professional.name)}
                                            </p>)}


                                            <p style={{ marginTop: '10px', fontSize: '13px' }} >
                                                Payment Mode: {capitalizeFirstLetter(order.paymentMode)}
                                            </p>

                                            <p className="slotTime">
                                                Slot: {formatOrderDateTime(order)}
                                            </p>

                                            <OrderTracker status={statusMap[order.orderStatus]} />



                                        </Card.Body>
                                        <hr />
                                        <Card.Footer className="order-card-footer">

                                            <div className={order.orderStatus === 'received' || order.orderStatus === 'completed' ? "order-header" : ""}>
                                                {order.orderStatus === 'received' && (
                                                    <button
                                                        variant="primary"
                                                        className="cancel-button"
                                                        onClick={() => handleCancel(order.orderId)}

                                                    >
                                                        Cancel
                                                    </button>
                                                )}

                                                {order.orderStatus === 'completed' && (
                                                    <div className="rating">
                                                        <StarRating orderId={order._id} />
                                                    </div>
                                                )}
                                                <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                    Total Amount: ₹{order.totalPrice.$numberDecimal}
                                                </p>
                                            </div>



                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <div className="display-message">
                            <div className="message-content">
                                <p>There are no bookings</p>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        </>

    );
};

export default OrderPage;
