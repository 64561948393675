import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import '../Css/ItemView.css';
import '../Css/CustomToast.css'
import '../Css/StarRating.css';
import Slider from "./Slider";
import { Button, Container, Row, Modal, Form, Col, Card, ListGroup } from "react-bootstrap";
import { getFoodItems, getFoodItemsByCategory, getServiceCategory, getServiceItems } from "../Services/ItemsApi";
import { useNavigate } from "react-router-dom";
import { clearBrowser, isTokenValid } from "../Services/Login";
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from "../Services/StringConversion";
import LoadingOverlay from "./LoadingOverlay";
import { CartContext } from "./CartContext";
import Cookies from "js-cookie";
import { addItemToCart } from "../Services/Cart";
import Menu from "./BottomMenu";
import Loader from "./Loader";
import { removeUnderScore, showSuccessMessage } from "../Services/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { faStarHalfAlt as halfStar } from '@fortawesome/free-solid-svg-icons';

const ItemsView = () => {
    const location = useLocation();
    const { cartCount, setCartCount } = useContext(CartContext);
    const { toastIdCount, setToastIdCount } = useContext(CartContext);
    const [processing, setProcessing] = useState(false);
    const toastId = useRef(null);

    // // Access the `menuItem` using optional chaining (`?.`)
    // const menuItem = location.state.item;

    // const navigate = useNavigate();

    // useEffect(() => {
    //     toast.dismiss(toastIdCount)
    //     window.scrollTo(0, 0);
    //     if (menuItem) {
    //         setProcessing(true)
    //         getFoodItemsByCategory(menuItem)
    //             .then((response) => {
    //                 setItems(response);
    //                 setProcessing(false)
    //             })
    //             .catch((error) => {
    //                 setProcessing(false)
    //                 console.log(error);
    //             });
    //     }

    // }, [menuItem, pathname]);

    // //To check if auth token is valid
    // isTokenValid(localStorage.getItem('token'))
    //     .then((response) => {
    //         localStorage.setItem('tokenValid', response.data.status);
    //         if (localStorage.getItem('tokenValid') !== 'true') {
    //             clearBrowser();
    //         }
    //     })
    //     .catch((error) => console.log(error));

    // const [items, setItems] = useState([]);
    // const [showModal, setShowModal] = useState(false); // State for modal visibility
    // const [selectedItem, setSelectedItem] = useState(null); // State for selected item
    // const [selectedQuantity, setSelectedQuantity] = useState(null); // State for selected quantity
    // const [processing, setProcessing] = useState(false);

    // const addToCart = (itemId, userId) => {
    //     const selectedItem = {
    //         ...items.find(item => item.id === itemId),
    //         userId: userId,
    //         // Add more properties as needed
    //     };
    //     if (selectedItem) {
    //         setSelectedItem(selectedItem);
    //         setShowModal(true);
    //     } else {
    //         console.error("Item not found");
    //     }
    // };

    // const handleCloseModal = () => {
    //     setShowModal(false);
    //     setSelectedItem(null); // Reset selected item when modal is closed
    //     setSelectedQuantity(null); // Reset selected quantity when modal is closed
    // };

    // const handleAddToCart = () => {
    //     setProcessing(true)
    //     if (selectedItem) {

    //         if (localStorage.getItem('login') !== 'true') {
    //             alert("You are not login, please login!")
    //             setProcessing(false)
    //             navigate('/login');
    //         } else {

    //             isTokenValid(localStorage.getItem('token'))
    //                 .then((response) => {

    //                     if (response.data.status === true) {
    //                         addItemToCart(selectedItem, selectedQuantity).then((response) => {
    //                             setProcessing(false)
    //                             setCartCount(response.cartitems.length);
    //                         }
    //                         ).catch((error) => {
    //                             setProcessing(false)
    //                             console.log(error)
    //                         });

    //                     }
    //                     else {
    //                         setProcessing(false)
    //                         alert("You are not login, please login!")
    //                         clearBrowser();

    //                         navigate('/login');
    //                     }
    //                 })

    //         }
    //     } else {
    //         console.error("No item selected");
    //     }
    //     handleCloseModal(); // Close modal after adding to cart
    // };
    const { pathname } = useLocation();

    const { serviceName, cityName } = useParams();
    const navigate = useNavigate();

    const [services, setServices] = useState();

    const [packages, setPackages] = useState();

    const { isLoading, setIsLoading } = useContext(CartContext);

    const [selectedProducts, setSelectedProducts] = useState({});

    useEffect(() => {

        toast.dismiss(toastIdCount)
        window.scrollTo(0, 0);
        setIsLoading(true);
        getServiceCategory(serviceName, cityName).then((response) => {
            if (response) {
                setServices(response);
                handlePackage(response[0].name, Cookies.get('city_name'));

                setTimeout(() => {
                    setIsLoading(false); // Allow rendering after 2 seconds
                }, 500);
            } else {
                console.error("Item not found");
                setIsLoading(false);
            }
        }).catch((error) => {
            console.log(error);
            setProcessing(false);
            setIsLoading(false);
        });
    }, [])

    const handlePackage = (serviceCategoryName, cityName) => {
        getServiceItems(serviceCategoryName, cityName).then((response) => {
            if (response) {
                setPackages(response);
                // Initialize selected product for each package
                const initialSelectedProducts = response.reduce((acc, pkg) => {
                    if (pkg.productDetails && pkg.productDetails.length > 0) {
                        acc[pkg._id] = {
                            selectedProductName: pkg.productDetails[0].name,
                            selectedProductPrice: pkg.productDetails[0].cityAndPrice.totalPrice.$numberDecimal,
                            itemId: pkg._id,
                            productId: pkg.productDetails[0]._id
                        };
                    }
                    return acc;
                }, {});
                setSelectedProducts(initialSelectedProducts);
            } else {
                console.error("Item not found");
            }
        }).catch((error) => {
            console.log(error);
            setProcessing(false);
            setIsLoading(false);
        });
    };

    const handleProductChange = (pkgId, productName, totalPrice, productId) => {
        setSelectedProducts((prevSelectedProducts) => ({
            ...prevSelectedProducts,
            [pkgId]: {
                selectedProductName: productName,
                selectedProductPrice: totalPrice,
                itemId: pkgId,
                productId: productId
            },
        }));
    };

    const handleAddToCart = async (pkgId) => {
        setProcessing(true);
        if (Cookies.get('login') === 'true') {
            if (Cookies.get('token')) {
                await isTokenValid(Cookies.get('token')).then((response) => {
                    if (response === true) {

                        const productId = selectedProducts[pkgId]?.productId;
                        const itemId = selectedProducts[pkgId]?.itemId;
                        const userId = Cookies.get('userId');
                        const cityId = Cookies.get('city_id');

                        addItemToCart(userId, itemId, productId, cityId).then((response) => {
                            console.log(response);
                            setCartCount(response.cartItems.length);
                            showSuccessMessage("Item added to cart", 2000)
                            setProcessing(false);
                        }).catch((error) => {
                            console.log(error);
                            setProcessing(false);
                            setIsLoading(false);
                        });

                    }
                }).catch((error) => {
                    console.log(error);
                    setProcessing(false);
                    setIsLoading(false);
                    toast.error("Session expired, please login again", { position: 'bottom-center' });
                    navigate('/login')
                })
            } else {
                toast.error("Please login before adding to cart", { position: 'bottom-center' });
                navigate('/login')
            }

        } else {
            toast.error("Please login before adding to cart", { position: 'bottom-center' });
            navigate('/login')
        }
    }

    if (isLoading) {
        return <Loader />; // Show a loading state before rendering the main content
    }

    return (
        <>
            {processing && <LoadingOverlay />}
            <div className="bg-color">
                <Container className="my-5"  >
                    <Row>
                        <Col md={3} >
                            <h2 className="salon-title">{services && services.length > 0 ? capitalizeFirstLetter(removeUnderScore(services[0]?.serviceDetails.name)) : 'No details available!'}</h2>
                            {/* <p className="text-muted">4.84 (4.8 M bookings)</p> */}
                            <div className=" scrollable-category ">
                                {services && services.map((item) => (
                                    <Row className="card-container  ">
                                        <Col key={item._id} className="side-bar mb-4  ">
                                            <Card onClick={() => handlePackage(item.name, Cookies.get('city_name') || '')} className="category-cards" >
                                                <Card.Body className="d-flex flex-column align-items-center">
                                                    <div className="image-containers">
                                                        <Card.Img src={item.image} className="card-images" />
                                                    </div>
                                                    <p className="modal-name text-center mt-3">
                                                        {capitalizeFirstLetter(removeUnderScore(item.name))}
                                                    </p>
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    </Row>
                                ))}
                            </div>
                            <div className="divider-line"></div>
                        </Col>

                        <Col md={8} className="scrollable-packages">
                            <h3 className="bestseller-title">
                                {packages && packages.length > 0 ? capitalizeFirstLetter(removeUnderScore(packages[0]?.serviceCategory.name)) : 'Coming soon in your city!'}
                            </h3>
                            {packages && packages.map((pkg) => (
                                <Card key={pkg._id} className="mb-2 package-card">
                                    <Card.Body className="package-card-body">
                                        <Row>
                                            <Col md={3}>
                                                <div className="item-wb-view">
                                                    <div className="pkg-image-container" >
                                                        <Card.Img src={pkg.image} className="package-images" />
                                                    </div>
                                                </div>

                                                <div className="item-ph-view">
                                                    <div className="pkg-image-container" >
                                                        <Card.Img src={pkg.image} className="package-images" />
                                                    </div>

                                                    <div className="item-ph-view-title">
                                                        {/* <p className=" float-end pcg-price"> ₹{selectedProducts[pkg._id]?.selectedProductPrice}</p> */}
                                                        <Card.Title className="package-title">{capitalizeFirstLetter(removeUnderScore(pkg.name))}</Card.Title>
                                                        <Card.Text className="price-duration">
                                                            <i class="bi bi-clock-history"></i> &nbsp;
                                                            {pkg.duration?.hours > 0 ? `${pkg.duration.hours} hours  ` : ''}
                                                            {pkg.duration?.minute > 0 ? `${pkg.duration.minute} minutes` : ''}
                                                        </Card.Text>

                                                        {/* for star rating */}
                                                        <div className="star-rating d-flex align-items-center">
                                                            {[...Array(5)].map((_, index) => {
                                                                if (pkg.totalRating && index < Math.floor(pkg.totalRating.$numberDecimal)) {
                                                                    // Full star
                                                                    return (
                                                                        <span key={index}>
                                                                            <FontAwesomeIcon className='item-star-icon' icon={solidStar} />
                                                                        </span>
                                                                    );
                                                                } else if (pkg.totalRating && index === Math.floor(pkg.totalRating.$numberDecimal) && pkg.totalRating.$numberDecimal % 1 !== 0) {
                                                                    // Half star
                                                                    return (
                                                                        <span key={index}>
                                                                            <FontAwesomeIcon className='item-star-icon' icon={halfStar} />
                                                                        </span>
                                                                    );
                                                                } else {
                                                                    // Empty star
                                                                    return (
                                                                        <span key={index}>
                                                                            <FontAwesomeIcon className='item-star-icon' icon={regularStar} />
                                                                        </span>
                                                                    );
                                                                }
                                                            })}
                                                            <span style={{ fontSize: '15px', marginTop: '10px', color: 'grey' }}>({pkg.review && pkg.review.length})</span>
                                                        </div>

                                                        <button
                                                            variant="primary"
                                                            className="add-package-btn"
                                                            style={{ marginRight: '20px' }}
                                                            onClick={() => handleAddToCart(pkg._id)}
                                                        >
                                                            Add to cart
                                                        </button>

                                                    </div>
                                                    <div className="item-ph-view-price">

                                                        <p className=" float-end pcg-price"> ₹{selectedProducts[pkg._id]?.selectedProductPrice}</p>


                                                    </div>

                                                </div>

                                            </Col>
                                            <Col md={9} style={{ padding: '8px' }}>
                                                <button
                                                    variant="primary"
                                                    className="float-end add-package-btn"
                                                    style={{ marginRight: '20px' }}
                                                    onClick={() => handleAddToCart(pkg._id)}
                                                >
                                                    Add to cart
                                                </button>
                                                <p className=" float-end pcg-price"> ₹{selectedProducts[pkg._id]?.selectedProductPrice}</p>

                                                <div style={{ marginTop: '-20px' }} className="item-wb-view">
                                                    <Card.Title className="package-title">{capitalizeFirstLetter(removeUnderScore(pkg.name))}</Card.Title>

                                                    <Card.Text className="price-duration">
                                                        <i class="bi bi-clock-history"></i> &nbsp;
                                                        {pkg.duration?.hours > 0 ? `${pkg.duration.hours} hours  ` : ''}
                                                        {pkg.duration?.minute > 0 ? `${pkg.duration.minute} minutes` : ''}
                                                    </Card.Text>

                                                    {/* for star rating */}
                                                    <div className="star-rating d-flex align-items-center">
                                                        {[...Array(5)].map((_, index) => {
                                                            if (pkg.totalRating && index < Math.floor(pkg.totalRating.$numberDecimal)) {
                                                                // Full star
                                                                return (
                                                                    <span key={index}>
                                                                        <FontAwesomeIcon className='item-star-icon' icon={solidStar} />
                                                                    </span>
                                                                );
                                                            } else if (pkg.totalRating && index === Math.floor(pkg.totalRating.$numberDecimal) && pkg.totalRating.$numberDecimal % 1 !== 0) {
                                                                // Half star
                                                                return (
                                                                    <span key={index}>
                                                                        <FontAwesomeIcon className='item-star-icon' icon={halfStar} />
                                                                    </span>
                                                                );
                                                            } else {
                                                                // Empty star
                                                                return (
                                                                    <span key={index}>
                                                                        <FontAwesomeIcon className='item-star-icon' icon={regularStar} />
                                                                    </span>
                                                                );
                                                            }
                                                        })}
                                                        <span style={{ fontSize: '17px', marginTop: '10px', color: 'grey' }}>({pkg.review && pkg.review.length})</span>
                                                    </div>

                                                    <p className="select-product-title">Select Product</p>
                                                </div>

                                                <div className="item-ph-view">
                                                    <p className="select-product-title">Select Product</p>


                                                </div>


                                                {pkg.productDetails.map((product, index) => (
                                                    <div key={index} className="varient-tags">
                                                        <Form.Check
                                                            type="radio"
                                                            aria-label={`radio ${index + 1}`}
                                                            className="item-custom-radio"
                                                            style={{ marginLeft: '-60px', marginRight: '2px', marginRight: '10px' }}
                                                            name={`variantRadio_${pkg._id}`} // Ensures each package's radios are grouped separately
                                                            value={product.name}
                                                            checked={selectedProducts[pkg._id]?.selectedProductName === product.name} // Check if this radio button is selected
                                                            onChange={() => handleProductChange(pkg._id, product.name, product.cityAndPrice.totalPrice.$numberDecimal, product._id)} // Update selected product
                                                        />
                                                        <button style={{ textAlign: 'left', color: 'black', flexGrow: 1, fontSize: '14px', marginTop: '-1px' }} onClick={() => handleProductChange(pkg._id, product.name, product.cityAndPrice.totalPrice.$numberDecimal, product._id)} className="item_quantities_button">
                                                            <p>{capitalizeFirstLetter(product.name)}</p>
                                                        </button>
                                                        {/* <p>{product.cityAndPrice.totalPrice.$numberDecimal}</p> */}
                                                    </div>


                                                ))}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ItemsView;
