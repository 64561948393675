import React, { useContext, useState } from 'react';
import '../Css/ContactForm.css';
import { sendMailToUser } from '../Services/Mail';
import Swal from 'sweetalert2';
import { CartContext } from './CartContext';
import Loader from './Loader';
import LoadingOverlay from './LoadingOverlay';



// You can either load SMTPJS from a CDN or install it via npm
// import emailjs from 'smtpjs';

function App() {
    const [processing, setProcessing] = useState(false);
    const { isLoading, setIsLoading } = useContext(CartContext);
    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        profession: '',
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setIsLoading(true);

        try {
            // Await the response from the sendMailToUser function
            await sendMailToUser(formData).then((response) => {

                setIsLoading(false);
                setIsLoading(false);
                Swal.fire({
                    title: 'Your Response Submitted',
                    text: 'We will get back to you!',
                    icon: 'success',
                });

            }).catch((error) => {
                console.log(error);
                alert('Error in sending message');
            });

        } catch (error) {
            // Catch any errors and display an alert if something goes wrong
            console.log(error);
            alert('Error in sending message');
        }
    };

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            {processing && <LoadingOverlay />}
            <div className='justify-content-center align-items-center d-flex' style={{ height: '600px', marginTop: '-50px' }}>
                <div className='App '>
                    <h2>Onboard</h2>
                    <form onSubmit={handleSubmit} className=' d-flex'>


                        <div>

                            <input
                                placeholder='Name'
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                className='input-1'
                            />
                        </div>

                        <div>
                            <input
                                placeholder='Mobile Number'
                                type="text"
                                id="mobileNumber"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleChange}
                                required
                                className='input-1'
                            />
                        </div>

                        <div>

                            <select
                                id="profession"
                                name="profession"
                                value={formData.profession}
                                onChange={handleChange}
                                required
                                className='select-1'
                            >
                                <option value="">Select Profession</option>
                                <option value="Beautician">Beautician</option>
                                <option value="Technician">Technician</option>
                                <option value="Electrician">Electrician</option>
                                <option value="Plumber">Plumber</option>
                            </select>
                        </div>

                        <button className='button-1' type="submit">Submit</button>

                    </form>
                </div>
            </div>
        </>

    );
}

export default App;
