import React, { useContext, useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../Css/Navigation.css';
import logoweb from '../Icon/logo-web.png';
import logomob from '../Icon/logo-mob.png';
import cart from '../Icon/cart.png';
import { Image } from "react-bootstrap";
import { clearBrowser, isTokenValid, logOut } from "../Services/Login";
import { Link, useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from "../Services/StringConversion";
import { CartContext } from "./CartContext";
import { getLocation, setCity } from "../Services/navigation";
import Cookies from "js-cookie";
import { getServices } from "../Services/ItemsApi";
import { getCart } from "../Services/Cart";
import { MenuItem, Select } from "@mui/material";
import { FaShoppingCart } from "react-icons/fa";
import { RiAccountCircleFill } from "react-icons/ri"
import { BsList } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { BsSearch } from "react-icons/bs";


const Navigation = () => {
    const { cartCount, setCartCount } = useContext(CartContext);
    const { userName, setUserName } = useContext(CartContext);

    const { items, setItems } = useContext(CartContext);

    // const [ cityId, setCityId ] = useState();
    // const [ cityName, setCityName ] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const { isLoading, setIsLoading } = useContext(CartContext);


    const [inputQuery, setInputQuery] = useState('');

    const [locations, setLocation] = useState([]); // Sample locations
    const [mealItems] = useState(['Main Course Veg', 'Main Course Non Veg', 'Rice & Biryani', 'Roti & Naan', 'Starter', 'Pizza', 'Burger']); // Sample meal items
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const navigate = useNavigate();

    const [showDropdown, setShowDropdown] = useState(false);

    const handleMouseEnter = () => setShowDropdown(true);
    const handleMouseLeave = () => setShowDropdown(false);

    // useEffect(() => {
    //     if (localStorage.getItem('login') === 'true') {
    //         isTokenValid(localStorage.getItem('token')).then((response) => {
    //             if (response.data.status === true) {
    //                 setUserName(localStorage.getItem('userName'));
    //             }
    //         });
    //     }

    // }, []);

    // To set location
    useEffect(() => {
        // Check if it's the user's first time visiting
        if (Cookies.get('city_id') === undefined || Cookies.get('city_name') === undefined) {
            Cookies.set('first_time', true, { expires: 7 });

        }

        getLocation().then((response) => {
            setLocation(response.data);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });

        // const defaultCity = response.data[0].address.city;

        const fetchLocation = async () => {
            try {

                const response = await getLocation().then((response) => {
                    setLocation(response.data);
                    const defaultCity = response.data[0].address.city;
                    // Check if this is the first time loading the page
                    if (Cookies.get('first_time') === 'true') {
                        console.log("Setting default city cookies");

                        // Set the default city cookies
                        Cookies.set('city_id', defaultCity._id, { expires: 7 });
                        Cookies.set('city_name', defaultCity.name, { expires: 7 });

                        // Set 'first_time' to 'false' to prevent further reloads
                        Cookies.set('first_time', false, { expires: 7 });
                        setIsLoading(false);

                        // Reload the page to apply the cookies
                        window.location.reload();
                    } else {
                        setSelectedLocation(Cookies.get('city_name'));
                    }

                }).catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });

            } catch (error) {
                console.error("Error fetching locations:", error);
            }
        };

        // Fetch location if it's the user's first time
        if (Cookies.get('first_time') === 'true') {
            fetchLocation();
        } else {
            // If not first time, set the selected location directly
            setSelectedLocation(Cookies.get('city_name'));
        }
    }, []);

    useEffect(() => {

        isTokenValid(Cookies.get('token')).then((response) => {
            if (response !== true) {
                navigate('/login');
            } else {
                getCart(Cookies.get('userId')).then((response) => {
                    setCartCount(response.data.cartItems.length);

                }).catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
            }
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
            setCartCount(0);
            if (error.response.status === 401) {
                Cookies.set('login', false, { expires: 7 });
            }
        });
    }, [])


    // useEffect(() => {
    //     if (inputQuery) {
    //         const filteredLocations = locations.filter(location =>
    //             location.toLowerCase().includes(inputQuery.toLowerCase())
    //         );
    //         const filteredMeals = mealItems.filter(item =>
    //             item.toLowerCase().includes(inputQuery.toLowerCase())
    //         );
    //         setFilteredSuggestions([...filteredLocations, ...filteredMeals]);
    //     } else {
    //         setFilteredSuggestions([]);
    //     }
    // }, [inputQuery, locations, mealItems]);

    const handleSearch = () => {
        if (inputQuery !== null && inputQuery !== "") {
            navigate(`/search/${inputQuery}`);
        }
    };

    const handleInputChange = (e) => {
        setInputQuery(e.target.value);
    };

    const handleLocation = async (event) => {
        const cityName = event.target.value;
        setSelectedLocation(cityName);

        await setCity(cityName).then((response) => {
            Cookies.set('city_name', response.data[0].address.city.name);
            Cookies.set('city_id', response.data[0].address.city._id);
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    // const handleSuggestionClick = (item) => {
    //     if (locations.includes(item)) {
    //         setSelectedLocation(item);
    //         setInputQuery('');
    //         setFilteredSuggestions([]);
    //     } else if (mealItems.includes(item)) {
    //         navigate(`/search/${item}`);
    //     }
    // };

    const Logout = () => {
        logOut().then(() => {
            navigate('/');
            clearBrowser();
        });
    };

    // if (isLoading) {
    //     return <div>loading........</div>; // Show a loading state before rendering the main content
    // }

    return (
        <>
            <Navbar style={{ borderBottom: '2px solid rgb(233, 236, 239)' }} className="fixed-top deep-red-bg" variant="dark" collapseOnSelect expand="lg">
                <Container >
                    <Navbar.Brand as={Link} to="/">
                        {/* <div className="logo-p">
                            <p style={{ margin: 0,  }}>Flex</p>
                            <span className="logo-c">Seva</span>
                        </div> */}
                        <div className="logo-p web">
                            {/* <Image  src={logoweb} className="logo-c" /> */}
                            <p style={{ margin: 0, }}>Flex</p>
                            <span className="logo-c">Seva</span>
                        </div>
                        <div className="logo-p mob">
                            {/* <Image  src={logomob} className="logo-c" /> */}
                            <p style={{ margin: 0, }}>Flex</p>
                            <span className="logo-c">Seva</span>
                        </div>
                    </Navbar.Brand>

                    <div className="location-selector location-cont-mob mob">
                        {/* <i style={{ color: '#922d48' }} class="bi bi-geo-alt-fill"></i> */}
                        <MdLocationPin style={{ color: '#922d48' }} />
                        <select
                            className="location-select"
                            onChange={handleLocation}
                            value={capitalizeFirstLetter(selectedLocation)}

                        >
                            {locations.map((location) => (
                                <option className="location-select-option">
                                    {capitalizeFirstLetter(location.address.city.name)}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* <div style={{marginLeft:'20px', textDecoration:'none'}}>
                        <i style={{marginRight:'5px'}} className="bi bi-person-fill "></i>
                        {Cookies.get('login') === 'true' ?<Link onClick={logOut} style={{textDecoration:'none', color:'black'}} >Logout</Link> : <Link style={{textDecoration:'none', color:'black'}} to='/login'>Login</Link>}
                    </div> */}


                    <Navbar.Toggle aria-controls="responsive-navbar-nav"><BsList style={{ color: 'black', fontSize: '35px' }} /></Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <div className="justify-content-centre search-and-lacation-web">
                            <div className="search-container-web web">
                                <div className="location-selector">
                                    <i style={{ color: '#922d48' }} class="bi bi-geo-alt-fill"></i>
                                    <select
                                        className="location-select"
                                        onChange={handleLocation}
                                        value={capitalizeFirstLetter(selectedLocation)}

                                    >
                                        {locations.map((location) => (
                                            <option className="location-select-option">
                                                {capitalizeFirstLetter(location.address.city.name)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Search for Services"
                                    value={inputQuery}
                                    onChange={handleInputChange}
                                />
                                <button className="search-button" onClick={handleSearch}>

                                    {/* <i class="bi bi-search"></i> */}
                                    <BsSearch className="sb" ></BsSearch>
                                </button>
                            </div>
                        </div>
                        <div className="justify-content-centre search-and-lacation">
                            <div>
                                <div className="search-container mob search-bar-con">
                                    <input
                                        type="text"
                                        className="search-bar-mob"
                                        placeholder="Search for Services"
                                        value={inputQuery}
                                        onChange={handleInputChange}
                                    />
                                    <button className="search-button-mob" onClick={handleSearch}>

                                        {/* <i class="bi bi-search"></i> */}
                                        <BsSearch className="sb" ></BsSearch>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div >
                            <div className=" nav-cart-container">
                                <Link className="nav-menu-item nav-menu-container-item-margin justify-content-center">

                                    <div className="account-style">

                                        {Cookies.get('login') === 'true' && (

                                            <NavDropdown show={showDropdown}
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                title={
                                                    <>
                                                        {/* <i className="bi bi-person-fill account-icon-large"></i> */}
                                                        <RiAccountCircleFill className="account-icon-large" />
                                                        <span style={{ marginLeft: '8px' }}>{capitalizeFirstLetter(userName) || capitalizeFirstLetter(Cookies.get('user_name'))}</span>
                                                    </>
                                                }
                                                id="collapsible-nav-dropdown"
                                                className="nav-menu-item-text nav-dropdowns"
                                            >
                                                <NavDropdown.Item className="dropdowns-items" as={Link} to="/profile">My Profile</NavDropdown.Item>
                                                <NavDropdown.Item className="dropdowns-items" as={Link} to="/order">Bookings</NavDropdown.Item>
                                                <NavDropdown.Item className="dropdowns-items" onClick={Logout}>Logout</NavDropdown.Item>
                                            </NavDropdown>
                                        )}
                                        {Cookies.get('login') !== 'true' && (
                                            <Link to="/login" className="nav-menu-item-text" style={{ textDecoration: 'none', marginTop: '5px' }}>Login</Link>
                                        )}

                                    </div>
                                </Link>
                                <Link to="/cart" className="nav-menu-item nav-menu-container-item-margin">

                                    <div style={{ marginTop: '8px' }}>
                                        <span className="nav-menu-cart-item-count">
                                            {cartCount}
                                        </span>
                                        {/* <i className="bi bi-cart-fill cart-icon-large"></i> */}
                                        <FaShoppingCart className="cart-icon-large" />
                                    </div>

                                    {/* <p>Cart</p> */}
                                </Link>
                                {/* <div>
                            {localStorage.getItem('login') !== 'true' && (
                                <NavDropdown title={capitalizeFirstLetter(userName)} id="collapsible-nav-dropdown">
                                    <NavDropdown.Item as={Link} to="/profile">My Profile</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/order">Order History</NavDropdown.Item>
                                    <NavDropdown.Item onClick={Logout}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            )}
                            {localStorage.getItem('login') === 'true' && (
                                <Link  to="/login">Login</Link>
                            )}

                        </div>
                        <div>
                            <Link  to="/cart" className="cart-container me-auto">
                                <img src={cart} className="cart-image" alt="Cart" />
                                Cart
                                {localStorage.getItem('cartItem') && (
                                    <span className="cart-item-count">
                                        {cartCount}
                                    </span>
                                )}
                            </Link>

                        </div> */}
                                {/* </Nav> */}
                            </div>

                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
        </>
    );
};
export { Navigation };
