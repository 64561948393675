import { PAYMENT_KEY, myAxios } from "./Helper";
import logo from '../Icon/delivery-man.png';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { deleteAllCartItem } from "./Cart";
import { deleteOrder } from "./Order";

async function proceedPayment(cityName, addressId, amount, userId, cartId, setProcessing, navigate, setCartCount, slotDate, slotTime, selectedPayment, setCartData) {
    setProcessing(true);

    if (selectedPayment === 'online') {
        return await myAxios.post('/api/order/user/' + userId + '/create', {
            "amount": amount,
            "addressId": addressId,
            "slotDate": slotDate,
            "slotTime": slotTime,
            "cityName": cityName,
            "paymentMode": selectedPayment
        }, {
            withCredentials: true,
        }).then((response) => {
            if (response.status === 200) {
                setProcessing(false);
                doPayment(response.data.id, response.data.amount, userId, setProcessing, navigate, setCartCount, setCartData);
            }
            return response;
        }).catch((error) => {
            setProcessing(false);
            if (error.response?.data?.message) {
                toast.error(error.response.data.message, { position: 'bottom-center' });
            } else if (error.response?.data?.error, { position: 'bottom-center' }) {
                toast.error(error.response?.data?.error, { position: 'bottom-center' });
            }
            else {
                toast.error("Something went wrong!", { position: 'bottom-center' });
                console.log(error);
            }
        });
    } else if (selectedPayment === 'after-service') {
        return await myAxios.post('/api/order/user/' + userId + '/create', {
            "amount": amount,
            "addressId": addressId,
            "slotDate": slotDate,
            "slotTime": slotTime,
            "cityName": cityName,
            "paymentMode": selectedPayment
        }, {
            withCredentials: true,
        }).then(async (response) => {
            if (response.status === 200) {
                try {
                    const cartResponse = await deleteAllCartItem(userId);
                    if (cartResponse) {
                        setCartData(null)
                        setCartCount(0);
                    }
                    const orderResponse = await updateOrder(response.data.id, " ", "pending", "received");
                    if (orderResponse) {
                        setProcessing(false);
                        await Swal.fire({
                            title: 'Order Successful',
                            text: 'Your service is booked. You will be redirected to the order page.',
                            icon: 'success',
                            confirmButtonText: 'Go to Orders',
                        });
                        navigate('/order');
                    }
                } catch (error) {
                    setProcessing(false);
                    toast.error("Something went wrong!", { position: 'bottom-center' });
                    console.log(error);
                }
            }
            return response;
        }).catch((error) => {
            setProcessing(false);
            if (error.response?.data?.message) {
                toast.error(error.response.data.message, { position: 'bottom-center' });
            } else if (error.response?.data?.error) {
                toast.error(error.response?.data?.error, { position: 'bottom-center' });
            }
            else {
                toast.error("Something went wrong!", { position: 'bottom-center' });
                console.log(error);
            }
        });
    } else {
        setProcessing(false)
        toast.error("Something went wrong!", { position: 'bottom-center' });
    }
}


async function updateOrder(orderId, paymentId, paymentStatus, orderStatus) {
    return await myAxios.put('/api/order/' + orderId + '/update', { "paymentId": paymentId, "paymentStatus": paymentStatus, "orderStatus": orderStatus }, {
        withCredentials: true,
    }).then((response) => response);
}

async function doPayment(orderId, amount, userId, setProcessing, navigate, setCartCount, setCartData) {

    let options = {
        key: PAYMENT_KEY,
        amount: amount,
        currency: 'INR',
        name: 'Eat & Bites',
        image: 'https://food-delivery-app-2.s3.eu-north-1.amazonaws.com/Logo/delivery-man.png',
        order_id: orderId,
        handler: async function (response) {  // Changed to async
            setProcessing(true);
            try {
                const cartResponse = await deleteAllCartItem(userId);
                if (cartResponse) {
                    setCartData(null);
                    setCartCount(0);
                }

                const orderResponse = await updateOrder(response.razorpay_order_id, response.razorpay_payment_id, "paid", "received");
                if (orderResponse) {

                    setProcessing(false);
                    await Swal.fire({
                        title: 'Order Successful',
                        text: 'Your service is booked. You will be redirected to the order page.',
                        icon: 'success',
                        confirmButtonText: 'Go to Orders',
                    });
                    navigate('/order');
                }
            } catch (error) {
                setProcessing(false);
                toast.error("Something went wrong!", { position: 'bottom-center' });
                console.log(error);
            }
        },
        modal: {
            ondismiss: function () {
                setProcessing(false);
                updateOrder(options.order_id, "null", "failed", "order not placed").then(() => {
                    Swal.fire({
                        title: 'Payment Failed',
                        text: 'Your payment was not successful. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'Go to Cart',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/cart');
                        }
                    });
                }).catch((error) => {
                    setProcessing(false);
                    toast.error("Something went wrong!", { position: 'bottom-center' });
                    console.log(error);
                });
            },
        },
        theme: {
            "color": "#3399cc"
        }
    };
    var rzp = new window.Razorpay(options);
    rzp.on('payment.failed', async function (response) {
        if (response) {
            setProcessing(true);
            try {
                await updateOrder(options.order_id, "null", "failed", "order not placed");
                setProcessing(false);
                await Swal.fire({
                    title: 'Payment Failed',
                    text: 'Oops! Something went wrong with your payment. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'Go to Cart',
                });
                navigate('/cart');
            } catch (error) {
                setProcessing(false);
                toast.error("Something went wrong!", { position: 'bottom-center' });
                console.log(error);
            }
        }
    });
    rzp.open();
}

export { proceedPayment, updateOrder };