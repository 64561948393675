import React, { useContext, useEffect, useRef, useState } from "react";
import '../Css/Home.css';
import '../Css/CustomToast.css';
import Slider from "./Slider";
import { Button, Container, Row, Modal, Form, Col, ModalBody, CardBody } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { getFoodItems, getServiceCategory, getServices } from "../Services/ItemsApi";
import { addItemToCart, getCart } from "../Services/Cart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearBrowser, isTokenValid } from "../Services/Login";
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from "../Services/StringConversion";
import LoadingOverlay from "./LoadingOverlay";
import { CartContext } from "./CartContext";
import Cookies from "js-cookie";
import { BASE_URL, removeUnderScore } from "../Services/Helper";
import Menu from "./BottomMenu";
import Loader from "./Loader";


const Home = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isLoading, setIsLoading } = useContext(CartContext);

    const { cartCount, setCartCount } = useContext(CartContext);
    const { toastIdCount, setToastIdCount } = useContext(CartContext);

    const { items, setItems } = useContext(CartContext);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [selectedItem, setSelectedItem] = useState([]); // State for selected item
    const [selectedQuantity, setSelectedQuantity] = useState(null); // State for selected quantity
    const [processing, setProcessing] = useState(false);
    const [content, setContent] = useState("Initial message");
    const toastId = useRef(null);

    useEffect(() => {
        toast.dismiss(toastIdCount)
        window.scrollTo(0, 0);

        setIsLoading(true)

        setTimeout(() => {
            getServices(Cookies.get('city_id'))
                .then((response) => {
                    console.log(response);
                    setItems(response);
                    setProcessing(false);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setProcessing(false);
                    setIsLoading(false);
                });
        }, 0);
    }, [pathname]);

    const showViewPage = async (serviceName, cityName) => {
        try {


            if (serviceName && cityName) {
                navigate(`/items/${serviceName}/${cityName}`);
            }

        } catch (error) {
            console.log(error);
        }

    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedItem(null); // Reset selected item when modal is closed
        setSelectedQuantity(null); // Reset selected quantity when modal is closed
    };

    const handleAddToCart = () => {
        setProcessing(true);
        if (selectedItem) {
            if (localStorage.getItem('login') !== 'true') {
                alert("You are not login, please login!");
                navigate('/login');
            } else {
                isTokenValid(Cookies.get('token'))
                    .then((response) => {
                        if (response.data.status === true) {
                            addItemToCart(selectedItem, selectedQuantity).then((response) => {
                                if (response) {
                                    setCartCount(response.cartitems.length);
                                    setProcessing(false);
                                }
                            }).catch((error) => {
                                setProcessing(false);
                                setIsLoading(false);
                                console.log(error);
                            });
                        } else {
                            alert("You are not login, please login!");
                            clearBrowser();
                            navigate('/login');
                        }
                    }).catch((error) => {
                        console.log(error);
                        setProcessing(false);
                        setIsLoading(false);
                    });
            }
        } else {
            console.error("No item selected");
        }
        handleCloseModal(); // Close modal after adding to cart
    };

    if (isLoading) {
        return <Loader />; // Show a loading state before rendering the main content
    }

    return (
        <>
        <div style={{paddingTop:'200px'}} className="bg-color">
            
            <p style={{textAlign: 'center'}}>This page is under construction, We will come soon in your city Thank You!</p>

          
        </div>
        
            {/* {processing && <LoadingOverlay />}
            <div className="bg-color">
                <Slider />
                <Container className="mg-5 home-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <p className="service-heading">Services At Home</p>
                    <div className="home-wrapper">
                        {
                            items.length > 0 ? (
                                items.map((item) => (
                                    <div className="h-p-cont" onClick={() => showViewPage(item.name, Cookies.get('city_name') || '')} key={item._id}>
                                        <div className="h-c-cont">


                                            <Card.Img src={item.image} className="card-image" />


                                            <h6 className="home-card-title">
                                                {capitalizeFirstLetter(removeUnderScore(item.name))}
                                            </h6>

                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div>Coming soon in your city!</div>
                            )
                        }
                    </div>
                </Container>
            </div> */}
        </>
    );
};

export default Home;
