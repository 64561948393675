import React from "react";
import { myAxios } from "./Helper";

async function getServices(cityId) {
    return await myAxios.get('/api/service/city/'+cityId+'/status?status=true',{
        withCredentials: true,
    }).then((response) => response.data)
};

async function getServiceCategory(serviceName, cityName) {
    return await myAxios.get('/api/service-category/city/'+cityName+'/service/'+serviceName+'?status=true',{
        withCredentials: true,
    }).then((response) => response.data)
};

async function getServiceItems(serviceCategoryName, cityName) {
    console.log(serviceCategoryName)
    console.log(cityName)
    return await myAxios.get('/api/service-item/city/'+cityName+'/service-category/'+serviceCategoryName+'?status=true',{
        withCredentials: true,
    }).then((response) => response.data)
};

async function searchItem(searchQuery, cityId) {
    return await myAxios.get('/api/service-item/city/'+cityId+'/search?status=true&searchQuery='+searchQuery,{
        withCredentials: true,
    }).then((response) => response.data)
};

export { getServices,getServiceCategory,getServiceItems, searchItem };