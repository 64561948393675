import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import '../Css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import { CartContext } from "./CartContext";

const Footer = () => {
    const { isLoading } = useContext(CartContext);

    // Uncomment this if you want to show a loading state before rendering the main content
    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    return (
        <div className="footer">
            <Container>
                <Row className="align-items-start">
                    <Col md={4}>
                        <h5 className="footer-heading">About Us</h5>
                        <p className="footer-description">
                            Welcome to FlexSeva!<br />
                            At FlexSeva, we bring a variety of essential services right to your doorstep. Whether you’re looking for a women’s home salon experience or reliable home appliance repair, we’ve got you covered.
                            We make it easy to get the services you need. 
                            We believe in providing quality service that fits your busy lifestyle. Let us take care of your needs while you relax and enjoy your time!
                        </p>
                    </Col>
                    <Col md={4}>
                        <h5 className="footer-heading">Quick Links</h5>
                        <ul className="footer-links">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h5 className="footer-heading">Contact Us</h5>
                        <p>Email: flexseva@gmail.com</p>
                        <p>Phone: </p>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/profile.php?id=61569973174522&mibextid=ZbWKwL" target="facebook" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <p className="footer-bottom">&copy; 2024 Food Delivery App. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
