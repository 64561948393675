import React, { useContext, useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Css/Cart.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCart, updateCart, deleteCartItem, generateSlot } from "../Services/Cart";
import deliveryImage from '../Image/delivery.jpg'
import deleteIcon from '../Icon/delete.png'
import { Button, Modal, Form, Spinner, Card, CardBody, ModalBody } from "react-bootstrap";
import { clearBrowser, isTokenValid } from "../Services/Login";
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { proceedPayment } from "../Services/payment";
import { getUserByUserId, getUserByUserName } from "../Services/User";
import { capitalizeFirstLetter } from "../Services/StringConversion";
import LoadingOverlay from "./LoadingOverlay";
import { toast } from "react-toastify";
import { ScrollToTop } from "../Services/Helper";
import { CartContext } from "./CartContext";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import Cookies from "js-cookie";
import Menu from "./BottomMenu";
import Loader from "./Loader";

const Cart = () => {

    const { cartCount, setCartCount } = useContext(CartContext);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { cartData, setCartData } = useContext(CartContext);
    const [processing, setProcessing] = useState(false);
    const [cartAmount, setCartAmount] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [slot, setSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [slotData, setSlotData] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const { isLoading, setIsLoading } = useContext(CartContext);

    const handleIncrement = (itemId, quantity) => {

        const userId = Cookies.get('userId');
        setProcessing(true);

        updateCart(itemId, userId, quantity + 1)
            .then((response) => {
                setProcessing(false);
                setCartData(response); // Update state after successful update
            })
            .catch((error) => {
                setProcessing(false);
                setIsLoading(false);
            }).finally(() => {
                setProcessing(false); // Set processing to false when operation completes
                setIsLoading(false);
            });
    };

    const handleDecrement = (itemId, quantity) => {
        setProcessing(true);

        const userId = Cookies.get('userId');
        if (quantity > 0) {
            // if (quantity === 1) {
            //     deleteCartItem(userId, itemId)
            //         .then(() => {
            //             setProcessing(true);
            //             getCart(userId).then((response) => {
            //                 if (response) {
            //                     setCartCount(response.cartitems.length);
            //                     setProcessing(false);
            //                 }
            //                 setCartData(response); // Update cart after deletion
            //             });
            //         })
            //         .then((response) => {
            //             setCartData(response); // Update state after deletion
            //         })
            //         .catch((error) => {
            //             setProcessing(false);
            //         }).finally(() => {
            //             // Set processing to false when operation completes
            //         });
            // } else {
            updateCart(itemId, userId, quantity - 1)
                .then((response) => {
                    setCartData(response); // Update state after successful update
                })
                .catch((error) => {
                    console.log(error);
                    setProcessing(false);
                    setIsLoading(false);
                }).finally(() => {
                    setProcessing(false);
                    setIsLoading(false);
                });
        }
    };

    const checkout = (cartData) => {

        const amount = cartData.totalPrice.$numberDecimal;
        const serviceName = cartData.cartItems[0].serviceName;

        setProcessing(true)
        getUserByUserId(Cookies.get('userId')).then((response) => {
            if (response) {

                setAddress(response.address);
            }
            cartData.cartItems.map((items) => {
                if (serviceName !== items.serviceName) {
                    toast.error("please select same services to proceed", { position: 'bottom-center' });
                } else {
                    generateSlot(serviceName).then((response) => {
                        setProcessing(false)
                        setSlotData(response)
                        setSlot(response[0].slots)
                        setSelectedDate(response[0].date)
                        setSelectedSlot(response[0].slots[0].time)
                    })
                }
            })
        }).catch((error) => {
            console.log(error);
            setProcessing(false);
            setIsLoading(false);
        })
        setShowModal(true);
        setCartAmount(amount);
    }

    // Function to handle date click
    const handleDateClick = (date) => {
        // Find the slots for the clicked date
        setProcessing(true);
        const dateData = slotData.find(entry => entry.date === date);
        if (dateData) {
            setSelectedDate(date);
            setSlot(dateData.slots);
            setSelectedSlot(dateData.slots[0].time);

            setTimeout(() => {
                setProcessing(false);
            }, 200);
        }
    };

    const handleSlotClick = (slot, date) => {
        setProcessing(true);

        console.log(date)
        console.log(slot)
        if (selectedDate) {
            setSelectedSlot(slot)

            setTimeout(() => {
                setProcessing(false);
            }, 200);
        }
    }


    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [address, setAddress] = useState(null); // State for set address
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);

    const handleChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    const handleAddressSelection = (address) => {
        setSelectedAddress(address);
    };


    const handleCloseModal = () => {
        setShowModal(false);
        // Reset selected quantity when modal is closed
    };

    const handlePayment = (selectedAddress, cartId, slotDate, slotTime, selectedPayment) => {
        setProcessing(true);
        if (selectedAddress) {
            if (selectedPayment !== null) {
                proceedPayment(Cookies.get('city_name'), selectedAddress, cartAmount, Cookies.get('userId'), cartId, setProcessing, navigate, setCartCount, slotDate, slotTime, selectedPayment, setCartData).then(() => {
                }).catch((error) => {
                    toast.error(error.data, { position: 'bottom-center' })
                    setProcessing(false);
                    setIsLoading(false);
                });
            }
        } else {
            setProcessing(false);
            toast.error("No address selected, Please select address!", { position: 'bottom-center' })
        }
        handleCloseModal(); // Close modal after adding to cart
    };


    const handleDelete = (itemId) => {

        const userId = Cookies.get('userId');
        deleteCartItem(userId, itemId).then((response) => {
            setCartData(response);
            setCartCount(response.cartItems.length);
        }).catch((error) => {
            console.error(error);
            setProcessing(false);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        toast.dismiss(localStorage.getItem('toastId'))
        setProcessing(true);
        setIsLoading(true);
        if (location.state?.showModal) {
            setShowModal(true);

            const cartData = JSON.parse(localStorage.getItem('cartData'));
            checkout(cartData);
        }

        isTokenValid(Cookies.get('token')).then((response) => {
            if (response !== true) {
                setProcessing(false);
                setIsLoading(false);
                navigate('/login');
            } else {
                getCart(Cookies.get('userId')).then((response) => {
                    console.log(response.data);
                    setProcessing(false)
                    setCartData(response.data);
                    localStorage.setItem('cartData', JSON.stringify(response.data));
                    setIsLoading(false);

                    // setCartAmount(response.totalPrice);
                });
            }
        }).catch((error) => {
            console.log(error);
            setProcessing(false);
            setIsLoading(false);
            if (error.response.status === 401) {
                alert("You are not authorized to use this app, please login");
                navigate('/login');
            }
        });

    }, [navigate, location.state, pathname]);

    if (cartData) {
        localStorage.setItem('cartItem', cartData.cartItems.length);
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            {processing && <LoadingOverlay />}
            <div className="bg-color">
                <Container className="my-5" >

                    {cartData && cartData.cartItems.length > 0 ? (
                        <Row>
                            <Col sm={2} >
                                {/* <img className="cart-image" src={deliveryImage} style={{ width: '400px', height: '500px' }}></img> */}
                            </Col>

                            <Col sm={5} className="scrollable-cart-items">
                                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Services Booking</p>
                                {/* Conditional rendering */}
                                {cartData && cartData.cartItems.map((item, index) => (
                                    <Row key={index} className="cart-card-container ">
                                        <Card className=" shadow-sm  rounded cart-card" >
                                            <Card.Body className="d-flex flex-column cart-card-body">

                                                <div className="first-row">
                                                    {/* <Col md={5}> */}
                                                    <div className="cart-head-style" >
                                                        <h6 className="mb-2 item-name" >
                                                            {capitalizeFirstLetter(item.itemName)}
                                                        </h6>
                                                        <p className="text-muted mb-1 product-name" >
                                                            ({capitalizeFirstLetter(item.productName)})
                                                        </p>
                                                        <p className="text-muted mb-1 item-duration" >
                                                            {capitalizeFirstLetter(item.duration)}
                                                        </p>
                                                        <p className="mb-0 item-price" >
                                                            ₹{item.totalPrice.$numberDecimal * item.quantity}
                                                        </p>

                                                    </div>

                                                    {/* </Col>
                                                <Col md={4}> */}
                                                    <div className="second-row">
                                                        <div className="inc-dec-button" style={{ width: '85px', height: '40px', marginLeft: '0px' }}>
                                                            <InputGroup size="sm" style={{}}>
                                                                <Button variant="outline-secondary" onClick={() => item.quantity > 1 ? handleDecrement(item._id, item.quantity) : ''} >-</Button>
                                                                <FormControl style={{ width: '32px', textAlign: 'center' }} type="text" value={item.quantity} disabled={processing} readOnly />
                                                                <Button variant="outline-secondary" onClick={() => handleIncrement(item._id, item.quantity)}>+</Button>
                                                            </InputGroup>
                                                        </div>
                                                        <button
                                                            variant="primary"
                                                            className="remove-button"
                                                            style={{ marginLeft: '5px', marginTop: '10px' }}
                                                            onClick={() => handleDelete(item._id)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </div>
                                                    {/* </Col>
                                                <Col md={3}> */}
                                                    <div className="cart-image-container">
                                                        <Card.Img src={item.image} className="cart-images" />
                                                    </div>



                                                    {/* </Col> */}
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between mt-3">

                                                    {/* Optional: Uncomment for a discounted price */}
                                                    {/* <h6 style={{ textDecoration: 'line-through', color: '#9e9b9b' }}>₹{item.price}</h6> */}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Row>

                                ))}
                            </Col>
                            <Col sm={3}>
                                {cartData && (
                                    <div className="card price-box border-0 shadow-sm p-3">
                                        <div className="card-header bg-transparent border-0">
                                            <h6 className="text-center text-uppercase font-weight-bold">Price Details</h6>
                                        </div>
                                        <div className="card-body">
                                            <Row className="mb-2">
                                                <Col xs={8}>
                                                    <h6 className="text-muted">Price</h6>
                                                </Col>
                                                <Col xs={4} className="text-right">
                                                    <p className="mb-0">₹{cartData.actualPrice.$numberDecimal}</p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col xs={8}>
                                                    <h6 className="text-muted">Discount</h6>
                                                </Col>
                                                <Col xs={4} className="text-right">
                                                    <p className="mb-0 text-success">₹-{cartData.discount.$numberDecimal}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-3" />
                                        <div className="card-footer bg-transparent border-0">
                                            <Row>
                                                <Col xs={8}>
                                                    <h6 className="font-weight-bold">Total Amount</h6>
                                                </Col>
                                                <Col xs={4} className="text-right">
                                                    <h6 className="font-weight-bold">₹{cartData.totalPrice.$numberDecimal}</h6>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                )}
                                <button className="checkout-button" onClick={() => checkout(cartData)}>Checkout</button>
                            </Col>
                        </Row>
                    ) : (
                        <div className="display-message">
                            <div className="message-content">
                                <p>No items in the cart</p>
                            </div>
                        </div>

                    )}
                </Container>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton style={{ backgroundColor: 'rgb(220, 223, 225)' }}>
                    <Modal.Title>Select address and slot</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'rgb(220, 223, 225)' }}>
                    <p>Select address</p>
                    {address && (
                        <div>
                            {address.map((val, index) => (
                                <div
                                    className="address-box"
                                    key={index}
                                    style={{ display: 'flex', alignItems: 'center', backgroundColor: selectedAddress === val ? 'lightblue' : 'transparent' }}
                                >
                                    <button
                                        className="modal-address-button"
                                        style={{ textAlign: 'left', color: 'black', flexGrow: 1, fontSize: '12px' }}
                                        onClick={() => handleAddressSelection(val._id)}
                                    >
                                        {capitalizeFirstLetter(val.address)}, {capitalizeFirstLetter(val.landmark)}, {val.pincode}, {capitalizeFirstLetter(val.cityId.name)}, {capitalizeFirstLetter(val.cityId.state)}
                                    </button>
                                    <Form.Check
                                        type="radio"
                                        aria-label={`radio ${index + 1}`}
                                        style={{ marginLeft: 'auto', marginRight: '20px', borderColor: 'black' }}
                                        name="addressRadio"
                                        value={val}
                                        checked={selectedAddress === val._id}
                                        onChange={() => handleAddressSelection(val._id)}
                                        className="custom-radio"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <Link className="modal-address-button" to="/address">+ Add new address</Link>
                </Modal.Body>
                {/* <Modal.Header style={{ backgroundColor: 'rgb(220, 223, 225)' }}>
                    <Modal.Title>Select Slot</Modal.Title>
                </Modal.Header> */}
                <ModalBody style={{ backgroundColor: 'rgb(220, 223, 225)' }}>
                    <p>Select Date</p>
                    {slotData && selectedDate && slotData.map((date) =>
                        <div className="align-items-center " style={{ display: 'inline-flex', paddingLeft: '2px', marginTop: '10px', marginLeft: '5px' }}>
                            <div className="align-items-center" style={{ display: 'block', fontSize: '15px' }}>
                                <p className="slot-date-p" >{date.day}</p>
                                <button class="slot-date" style={{
                                    border: selectedDate === date.date ? '3px solid white' : 'none',
                                    borderColor: selectedDate === date.date ? '#922d48' : 'gray'
                                }} key={date.date} onClick={() => handleDateClick(date.date)}>
                                    {date.date.split('-')[2]}
                                </button>
                            </div>

                        </div>
                    )
                    }
                    <p style={{ marginTop: '10px' }}> Select Time</p>
                    {selectedDate && slot.map((slot, index) =>



                        <div style={{ display: 'inline-flex', marginTop: '10px', paddingLeft: '2px' }}>
                            <button class="slot-time" style={{
                                border: selectedSlot === slot.time ? '3px solid white' : 'none',
                                borderColor: selectedSlot === slot.time ? '#922d48' : 'gray'
                            }} key={slot.time} onClick={() => handleSlotClick(slot.time, selectedDate)}>
                                {slot.time}
                            </button>
                        </div>

                    )
                    }
                    <p style={{ marginTop: '10px' }}> Select Payment Mode</p>
                    <div style={{ display: 'flex' }}>
                        <Form.Check
                            type="radio"
                            value="online"
                            checked={selectedPayment === 'online'}
                            onChange={handleChange}
                            className="custom-radio"
                            style={{ marginLeft: '-50px', marginBottom: '5px' }}
                        />
                        <label style={{ marginLeft: '10px', color: 'black' }}> Online</label>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <Form.Check
                            type="radio"
                            value="after-service"
                            checked={selectedPayment === 'after-service'}
                            onChange={handleChange}
                            className="custom-radio"
                            style={{ marginLeft: '-50px' }}
                        />
                        <label style={{ marginLeft: '10px', color: 'black' }}>Pay after service</label>
                    </div>
                </ModalBody>
                <Modal.Footer>
                    <Container className="d-flex justify-content-center">
                        <Button variant="primary" onClick={() => {
                            if (selectedAddress === null) {
                                toast.error("please selecct an address!", { position: 'bottom-center' })
                            } else if (selectedPayment === null) {
                                toast.error("please select a payment mode!", { position: 'bottom-center' })
                            } else {
                                handlePayment(selectedAddress, cartData._id, selectedDate, selectedSlot, selectedPayment)
                            }
                        }} className="modal-payment-button">
                            Proceed to payment
                        </Button>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Cart;
