import { myAxios } from "./Helper";

async function sendMailToUser(formData) {
    try {
        const response = await myAxios.post('/api/mail/send-email', formData,
            {
                withCredentials: true
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error adding item to cart:", error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export {sendMailToUser}